import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery } from "gatsby"
import remark from "remark"
import remarkHtml from "remark-html"
import recommended from "remark-preset-lint-recommended"
import Img from 'gatsby-image/withIEPolyfill'

import FreeDiscussionButtonModal from "../FreeDiscussionButtonModal"

import "./styles.scss"

const mdToHtml = content => {
  return remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(content)
    .toString()
}

const PhoneNumberButton = ({ className, text }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phoneNumber
        }
      }
    }
  `)

  const { phoneNumber } = data.site.siteMetadata

  return (
    <a href={`tel:${phoneNumber}`} className={className}>
      {text}
    </a>
  )
}

const ProperLink = ({ to, formButtonName, text, type, className = "" }) => {
  switch (type) {
    case "external":
      return (
        <a href={to} className={className} target="_blank" rel="noreferrer">
          {text}
        </a>
      )
    case "modal":
      return <FreeDiscussionButtonModal className={className} name={formButtonName} text={text} />
    case "tel":
      return <PhoneNumberButton className={className} text={text} />
    default:
      return (
        <Link to={to} className={className}>
          {text}
        </Link>
      )
  }
}

const HeroButtons = ({ buttonLinks, formButtonName }) => {
  if (!!buttonLinks.length) {
    return (
      <div className="row flex-column flex-xl-row align-items-center align-items-sm-start">
        {buttonLinks.map((button, i) => {
          const margin = i === buttonLinks.length - 1 ? `` : ` mb-4 mb-xl-0`

          return (
            <div
              key={`buttonLink-${i}`}
              className={`col-9 col-sm-7 col-lg-6 col-xl-3 ${margin}`}
            >
              <ProperLink
                to={button.linkTo}
                text={button.linkText}
                type={button.type}
                formButtonName={formButtonName}
                className={`btn btn-block btn-${button.linkStyle}`}
              />
            </div>
          )
        })}
      </div>
    )
  }

  return ""
}

const HeroSection = ({
  id,
  bgImage,
  className,
  subTitle,
  buttonLinks,
  children,
  formButtonName,
}) => {
  return (
    <section className={`landing hero-section ${className}`} id={id}>

      <Img
        fadeIn={false}
        loading="eager"
        fluid={bgImage.childImageSharp.fluid}
        className="hero-section-bg-image"
        style={{ position: "absolute" }}
      />

      {/* <div className="hero-section-bg-image">
        <div aria-hidden="true" style={{ width: '100%', paddingBottom: '67%' }}>
        <picture>
          <source
            type="image/webp"
            srcSet={bgImage.childImageSharp.fluid.srcSetWebp}
            sizes={bgImage.childImageSharp.fluid.sizes}
          />
          <source
            srcSet={bgImage.childImageSharp.fluid.srcSet}
            sizes={bgImage.childImageSharp.fluid.sizes}
          />
          <img
            sizes={bgImage.childImageSharp.fluid.sizes}
            srcSet={bgImage.childImageSharp.fluid.srcSet}
            src={bgImage.childImageSharp.fluid.src}
            alt=""
          />
        </picture>
        </div>
      </div> */}

      <div className="overlay"></div>
      <div className="blue-overlay"></div>
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-12 d-flex flex-column justify-content-center">
            {children}

            {!!subTitle && (
              <div
                className="font-medium mb-5 hero-subtitle-container"
                dangerouslySetInnerHTML={{ __html: mdToHtml(subTitle.content) }}
              />
            )}

            <HeroButtons buttonLinks={buttonLinks} formButtonName={`herosection-${formButtonName}`} />
          </div>
        </div>
      </div>
    </section>
  )
}

HeroSection.defaultProps = {
  className: "",
  id: "",
  subTitle: false,
  buttonLinks: [],
}

HeroSection.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  buttonLinks: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default HeroSection
