import React, { useRef, useState } from "react"
import swal from "sweetalert"
import PropTypes from "prop-types"
import Recaptcha from "react-google-recaptcha";

import { useForm } from "react-hook-form"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const RECAPTCHA_KEY = "6Lcac30bAAAAAG59_ff0nQna52HYesgLS7g1IDQ-";

const FreeDiscussionForm = ({ isHidden, closeModal, name }) => {
  const { register, handleSubmit, errors } = useForm()
  const freeDiscussionForm = useRef(null)
  const recaptchaRef = React.createRef()
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const buttonName = `free-discussion-${name}`

  const showSwal = ({ error = false }) => {
    if (error) {
      swal({
        title: "Oops!",
        text: "Something went wrong.",
        icon: "error",
      })
    } else {
      swal({
        title: "Thank You!",
        text: "We Have Received Your Enquiry",
        icon: "success",
        button: "Close",
      }).then(willCloseModal => {
        if (willCloseModal) {
          closeModal()
        } else {
          freeDiscussionForm.current.reset()
        }
      })
    }

  }

  const onSubmit = data => {
    const recaptchaValue = recaptchaRef.current.getValue()
    const spamFilters = ["estatesplus.com.au", "seo", "greetings", "contact us", "ranks", "company", "read more", "test"];
    const isSpamMessage = spamFilters.some(v => data.clientMessage.toLowerCase().includes(v));

    if (isSpamMessage) {
      showSwal({ error: false });
      console.error("Error: Message not recorded, recognized as spam");
    } else {
      // post data using fetch to submit data properly
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          'g-recaptcha-response': recaptchaValue,
          ...data,
        }),
      })
        .then(() => {
          showSwal({ error: false });
        })
        .catch(error => {
          showSwal({ error: true });
        })
    }
  }

  return (
    <form
      className={`contact-form ${isHidden ? "d-none" : ""}`}
      data-netlify="true"
      ref={freeDiscussionForm}
      name="free-discussion-form"
      netlify-honeypot="bot-field"
      data-netlify-recaptcha="true"
      onSubmit={handleSubmit(onSubmit)}
    >
      <input
        type="hidden"
        name="form-name"
        ref={register()}
        value="free-discussion-form"
      />

      {/* Honeypot field */}
      <div className="d-none">
        <input
          name="bot-field"
          ref={register()}
        />
      </div>

      {/* Client First Name */}
      <div className="form-group mb-2 p-0 pos-rel">
        <input
          type="text"
          className="form-control"
          placeholder="First Name"
          name="clientFirstName"
          ref={register({ required: true })}
        />

        <div className="focus-highlight"></div>
        {errors.clientFirstName && (
          <span className="text-danger">Required</span>
        )}
      </div>

      {/* Client Surname */}
      <div className="form-group mb-2 p-0 pos-rel">
        <input
          type="text"
          className="form-control"
          placeholder="Surname"
          name="clientSurname"
          ref={register({ required: true })}
        />

        <div className="focus-highlight"></div>
        {errors.clientSurname && <span className="text-danger">Required</span>}
      </div>

      {/* Client Phone */}
      <div className="form-group mb-2 p-0 pos-rel">
        <input
          type="text"
          className="form-control mr-2"
          placeholder="Phone"
          name="clientPhone"
          ref={register({ required: true })}
        />
        <div className="focus-highlight"></div>
        {errors.clientPhone && <span className="text-danger">Required</span>}
      </div>

      {/* Client Email */}
      <div className="form-group mb-2 p-0 pos-rel">
        <input
          type="text"
          className="form-control"
          placeholder="e.g. john@hotmail.com"
          name="clientEmail"
          ref={register({
            required: true,
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
        />

        <div className="focus-highlight"></div>
        {errors.clientEmail && errors.clientEmail.type === "required" && (
          <span className="text-danger">Required</span>
        )}

        {errors.clientEmail && errors.clientEmail.type === "pattern" && (
          <span className="text-danger">Looks like this email is invalid</span>
        )}
      </div>

      {/* Client Message */}
      <div className="form-group mb-3 p-0 pos-rel">
        <textarea
          className="form-control"
          id="clientMessage"
          placeholder="How We Can Help You"
          rows="5"
          name="clientMessage"
          ref={register({ required: true })}
        />
        <div className="focus-highlight"></div>
        {errors.clientMessage && <span className="text-danger">Required</span>}
      </div>

      <div className="form-group mb-3 p-0">
        <Recaptcha
          ref={recaptchaRef}
          sitekey={RECAPTCHA_KEY}
          size="normal"
          id="recaptcha-google"
          onChange={() => setButtonDisabled(false)}
        />
      </div>

      {/*  */}
      <button className={`btn btn-primary btn-block ${buttonName}`} disabled={buttonDisabled}>Send</button>
    </form>
  )
}

FreeDiscussionForm.propTypes = {
  isHidden: PropTypes.bool,
  name: PropTypes.string.isRequired,
  closeModal: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

FreeDiscussionForm.defaultProps = {
  isHidden: false,
  closeModal: false,
}

export default FreeDiscussionForm
