import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import FreeDiscussionButtonModal from "../FreeDiscussionButtonModal"
import { GoCheck } from "react-icons/go";
import "./styles.scss"

const PriceDetails = ({ usePrice, price, priceText }) => {
  if (!!usePrice) {
    return (
      <>
        <span className="text-black-50 font-size-lg-4">$</span>
        {price}
      </>
    )
  }

  return <span className="font-size-lg-4 text-center">{priceText}</span>
}

const PriceButton = ({ hideButton, text, type, to, inEditor, formButtonName }) => {
  const className = "btn btn-primary btn-block btn-md mb-5"

  if (!!hideButton) {
    return ""
  }

  const buttonComponent = () => {
    let modifiedType = type
    let href = to

    if (modifiedType === "modal" && inEditor) {
      modifiedType = "external"
      href = "#"
    }

    switch (modifiedType) {
      case "modal":
        return <FreeDiscussionButtonModal className={className} text={text} name={formButtonName} />
      case "external":
        return (
          <a className={className} href={href} target="_blank" rel="noreferrer">
            {text}
          </a>
        )
      default:
        return (
          <Link className={className} to={href}>
            {text}
          </Link>
        )
    }
  }

  return <div className="col">{buttonComponent()}</div>
}

const PriceColumn = ({
  formButtonName,
  inEditor,
  title,
  subTitle,
  usePrice,
  price,
  priceText,
  hideButton,
  linkText,
  linkType,
  linkTo,
  points,
}) => {
  return (
    <div className="d-flex flex-column price-column">
      <div className="price-header py-4 px-5 bg-accent text-white d-flex flex-column align-items-center justify-content-center">
        <h3 className="font-bold text-center">{title}</h3>
        <p className="text-center mb-0">{subTitle}</p>
      </div>
      <div className="price-details py-4 px-4 d-flex flex-column align-items-center justify-content-start">
        <div className="font-bold mb-3 d-flex align-items-center font-size-lg-6 priceText">
          <PriceDetails
            usePrice={usePrice}
            price={price}
            priceText={priceText}
          />
        </div>

        <PriceButton
          formButtonName={formButtonName}
          hideButton={hideButton}
          text={linkText}
          type={linkType}
          to={linkTo}
          inEditor={inEditor}
        />

        <div className="d-flex flex-column">
          {!!points.length &&
            points.map((point, i) => {
              return (
                <div
                  key={`point-${i}`}
                  className="d-flex justify-content-start align-items-center mb-3"
                >
                  <GoCheck className="flex-shrink-0" style={{ fontSize: '1.5rem' }} />
                  <div className="pl-3">
                    <p className="m-0">{point}</p>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

const PricingTableTemplate = ({ columns, formButtonName, inEditor = false }) => {
  return (
    <div className="row justify-content-center">
      {columns.map((col, i) => {
        const mb = i !== columns.length - 1 ? "mb-5" : "mb-0"

        return (
          <div
            key={`column-${i}`}
            className={`col-12 col-lg-4 px-4 px-sm-7 px-md-10 px-lg-2 px-xl-4 ${mb} mb-lg-0`}
          >
            <PriceColumn
              formButtonName={formButtonName}
              inEditor={inEditor}
              title={col.title}
              subTitle={col.subTitle}
              usePrice={col.usePrice}
              price={col.price}
              priceText={col.priceText}
              hideButton={col.hideButton}
              linkText={col.linkText}
              linkType={col.linkType}
              linkTo={col.linkTo}
              points={col.points}
            />
          </div>
        )
      })}
    </div>
  )
}

const PricingTable = ({ tableName, children }) => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        frontmatter: { sectionKey: { eq: "sections/pricing-tables" } }
      ) {
        frontmatter {
          tables {
            name
            columns {
              title
              subTitle
              usePrice
              price
              priceText
              hideButton
              linkText
              linkType
              linkTo
              points
            }
          }
        }
      }
    }
  `)

  const { tables } = data.markdownRemark.frontmatter
  const selectedTable = tables.find(table => table.name === tableName)

  return (
    <div className="container section-gap">
      <div className="row">
        <div className="col-12 mb-5">{children}</div>
        <div className="col-12">
          <PricingTableTemplate columns={selectedTable.columns} formButtonName="pricing-table" />
        </div>
      </div>
    </div>
  )
}

PricingTable.propTypes = {
  tableName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default PricingTable
