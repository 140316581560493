import React, { useState } from "react"
import PropTypes from "prop-types"
import { Modal } from "react-bootstrap"

import FreeDiscussionForm from "../FreeDiscussionForm"

import styles from "./styles.module.scss"

const FreeDiscussionButtonModal = ({ text, className, name }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <button onClick={handleShow} className={className}>
        {text}
      </button>

      <FreeDiscussionForm isHidden={true} name="buffer" />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header
          className={`bg-light justify-content-center align-items-center ${styles.modalHeader}`}
        >
          <Modal.Title className="font-bold font-header mx-auto d-flex flex-column flex-sm-row justify-content-center align-items-center font-size-lg-3">
            We Will Call You <span className="d-none d-sm-flex mx-2"></span>{" "}
          </Modal.Title>
          <button
            onClick={handleClose}
            type="button"
            className={`close ${styles.modalCloseButton}`}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <FreeDiscussionForm closeModal={handleClose} name={name} />
        </Modal.Body>
      </Modal>
    </>
  )
}

FreeDiscussionButtonModal.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}

FreeDiscussionButtonModal.defaultProps = {
  className: "",
}

export default FreeDiscussionButtonModal
