import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

import PreviewCompatibleImage from "../PreviewCompatibleImage"
import FreeDiscussionButtonModal from "../FreeDiscussionButtonModal"

const FreeDiscussion = ({ tagline, name, children }) => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        frontmatter: { sectionKey: { eq: "sections/free-discussion" } }
      ) {
        frontmatter {
          title
          defaultTagline
          description
          modalButtonText
          callUsButtonText
          imageAlt
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          phoneNumber
          phoneNumberFormat
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark
  const { siteMetadata } = data.site
  const mainTagLine = !!tagline ? tagline : frontmatter.defaultTagline

  return (
    <div className="container section-gap">
      <div className="row">
        <div className="col-lg-5 col-xl-6 d-flex align-items-center">
          <PreviewCompatibleImage
            src={frontmatter.image}
            alt={frontmatter.imageAlt}
          />
        </div>
        <div className="col-lg-7 col-xl-6">
          <h2 className="mb-4 font-bold text-center text-lg-left">
            <span className="d-block">{mainTagLine}</span>
            <span className="bg-accent text-white mt-3 d-inline-block px-3 py-2">
              {frontmatter.title}
            </span>
          </h2>
          {!!children ? children : (
            <p className="mb-5 text-center text-lg-left">
              {frontmatter.description}
            </p>
          )}
          <div className="row flex-column flex-sm-row align-items-center align-items-sm-start">
            <div className="col-8 col-sm-6 col-lg-7 col-xl-6 mb-4 mb-sm-0">
              <a
                href={`tel:${siteMetadata.phoneNumber}`}
                className="btn btn-block btn-primary btn-lg"
              >
                {`${frontmatter.callUsButtonText} ${siteMetadata.phoneNumberFormat}`}
              </a>
            </div>
            <div className="col-8 col-sm-6 col-lg-5 col-xl-6">
              <FreeDiscussionButtonModal
                name={name}
                className="btn btn-block btn-outline-secondary btn-lg"
                text={frontmatter.modalButtonText}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

FreeDiscussion.propTypes = {
  tagline: PropTypes.string,
  name: PropTypes.string.isRequired,
}

FreeDiscussion.defaultProps = {
  tagline: "",
}

export default FreeDiscussion
